















import { FetchProviderFeaturesRequested } from '@/+state/capabilities/provider-features/events';
import { capabilitiesStore } from '@/+state/capabilities/store';
import { hasEnrollments as anyEnrollments } from '@/+state/enrollments/projections';
import {
  EditPatientDialogSave,
  EditPatientFailed,
  PatientEdited,
} from '@/+state/patient-details/events';
import { patientsDetailStore as patientStore } from '@/+state/patient-details/store';
import { providersForSelect } from '@/+state/providers/projections';
import { defaultAlertMessage } from '@/shared/content';
import { PatientUpdatePayload } from '@/shared/patient-update-payload.interface';
import { DialogRef, DIALOG_PROPS } from '@conversa/bedazzled/src/dialog';
import { ofType, select, events$, emit } from '@conversa/reflex';
import {
  defineComponent,
  onBeforeUnmount,
  reactive,
  ref,
} from '@vue/composition-api';
import { isValid, parseISO } from 'date-fns';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import EnrollmentCreateDialogVue from '../enrollments/EnrollmentCreateDialog.vue';
import EditPatientDialog from './EditPatientDialog.vue';
import { providerFeaturesSmsEnabled } from '@/+state/capabilities/provider-features/projections';

/*
 * When we get API validation errors the API sends back a field and a message, this
 * maps the programmatic field name from the API to a name that matches the UI
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const validationFieldMap = {
  providerCode: 'Provider',
  clinicPatientCode: 'Patient ID',
  firstName: 'First Name',
  lastName: 'Last Name',
  mobilePhone: 'Phone',
  email: 'Email',
  preferredLanguage: 'Preferred Language',
  dateOfBirth: 'Date of Birth',
  gender: 'Gender',
};

export default defineComponent({
  components: {
    EditPatientDialog,
  },
  props: [...DIALOG_PROPS],
  setup(_, context) {
    const dialog = DialogRef();
    const capabilities = capabilitiesStore.inject();
    const patientsDetailStore = patientStore.inject();
    const hasEnrollments = select(anyEnrollments).value;
    const providerList = select(providersForSelect);
    const clinicPatientCode = patientsDetailStore.data?.clinicPatientCode;
    const smsEnabled = select(providerFeaturesSmsEnabled).value;

    const destroy$ = new Subject();

    const inputs: PatientUpdatePayload = reactive({
      providerCode: patientsDetailStore.data?.providerCode,
      firstName: patientsDetailStore.data?.firstName,
      lastName: patientsDetailStore.data?.lastName,
      mobilePhone: patientsDetailStore.data?.mobilePhone,
      email: patientsDetailStore.data?.email,
      preferredLanguage: patientsDetailStore.data?.preferredLanguage || 'en',
      dateOfBirth: patientsDetailStore.data?.dateOfBirth?.replace(/T.*/, ''),
      gender: patientsDetailStore.data?.gender || '',
      preferredCommunicationChannel: smsEnabled
        ? patientsDetailStore.data?.preferredCommunicationChannel || 'sms'
        : 'email',
      state: patientsDetailStore.data?.state || '',
      secondaryIds: patientsDetailStore.data?.secondaryIds || [],
    });

    const alertMessage = ref(null);

    const resetAlert = () => {
      alertMessage.value = null;
    };

    events$
      .pipe(ofType(EditPatientFailed), takeUntil(destroy$))
      .subscribe(event => {
        if (event.payload.error) {
          alertMessage.value = event.payload.error;
          return;
        }
        alertMessage.value = {
          message: defaultAlertMessage,
        };
      });

    events$.pipe(ofType(PatientEdited), takeUntil(destroy$)).subscribe(() => {
      if (hasEnrollments) {
        dialog.close();
      } else {
        dialog.open(EnrollmentCreateDialogVue, {
          fullscreen: true,
          closable: false,
        });
      }
    });

    onBeforeUnmount(() => {
      destroy$.next(null);
      destroy$.complete();
    });

    return {
      isLoadingPatient: patientsDetailStore.profileLoading,
      demographicCapabilities: capabilities.capabilities.demographics,
      inputs,
      alertMessage,
      providerList,
      clinicPatientCode,
      canSeeLanguageSelector:
        capabilities.capabilities.languages.spanishEnabled,
      cancel: () => {
        resetAlert();
        context.emit('dialog-closed');
      },
      save() {
        resetAlert();

        const allowed = {
          dateOfBirth: this.demographicCapabilities?.dateOfBirthEnabled,
          gender: this.demographicCapabilities?.genderEnabled,
          state: this.demographicCapabilities?.stateEnabled,
        };

        const allowedInputs: PatientUpdatePayload = {};

        for (const key in inputs) {
          if (allowed[key] === undefined || allowed[key] === true) {
            allowedInputs[key] = inputs[key];
          }
        }

        const inputsWithHidden = {
          ...allowedInputs,
          clinicPatientCode,
        };

        emit(EditPatientDialogSave(inputsWithHidden));
      },
      onProviderChange: providerCode => {
        emit(FetchProviderFeaturesRequested({ providerCode }));
      },
    };
  },
});
