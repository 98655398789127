import { defaultAlertMessage } from '../content';
import { AxiosError } from 'axios';

// These are the only HTTP status codes that we care to share messages from
const httpStatusWithMessage = [400, 404, 422];

class InvalidTokenError extends Error {
  constructor(message) {
    super(message);
    this.name = 'InvalidTokenError';
  }
}

function isAuthError(
  error: InvalidTokenError | unknown,
): error is InvalidTokenError {
  return error instanceof InvalidTokenError;
}

function isAxiosError(error: AxiosError | unknown): error is AxiosError {
  return !!(error as AxiosError).response;
}

export function handleApiError(store, error) {
  if (typeof store.error === 'undefined') {
    console.warn(
      'handleApiError: store does not have space for error defined, exiting early',
    );
    console.log(error);
    return;
  }

  if (!isAxiosError(error)) {
    if (!isAuthError(error)) {
      console.log(error);
    }
    return;
  }

  if (httpStatusWithMessage.includes(error.response.status)) {
    if (error.response.data.invalidProperties) {
      store.error = {
        code: error.response.status,
        message: error.response.statusText,
        endpoint: error.response.config.url,
        details: {
          errors: error.response.data.invalidProperties,
        },
      };
    } else {
      store.error = {
        endpoint: error.response.config.url,
        code: error.response.status,
        ...error.response.data
      };
    }
  } else {
    store.error = {
      code: error.response.status,
      message: defaultAlertMessage,
      endpoint: error.response.config.url,
    };
  }
}
