import { ErrorState } from '@/shared/models';
import { store } from '@conversa/reflex';
import { CapabilitiesDto } from '../+plugins/responses/context-response';

interface CapabilitiesState {
  capabilities: CapabilitiesDto;
  loading: boolean;
  error: ErrorState;
}

export const capabilitiesInitialState: CapabilitiesState = {
  capabilities: {
    demographics: {
      dateOfBirthEnabled: false,
      genderEnabled: false,
      stateEnabled: false,
      patientIdCreationEnabled: false,
    },
    features: {
      alertingEnabled: false,
      restrictEnrollment: false,
      smsEnabled: true,
    },
    languages: { spanishEnabled: false },
    international: { datetimeFormat: 'MM/DD/YYYY HH:MM' },
  },
  loading: false,
  error: null,
};

export const capabilitiesStore = store(capabilitiesInitialState);
